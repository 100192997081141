import moment from 'moment'

// Update an measurementPoint that returns a new updated measurement point
const updateMeasurementPointExport = (measurementPoint, newExportsValue) => {
  return {
    ...measurementPoint,
    MpInExports: newExportsValue,
    MpDateLastChanged: moment().format('YYYY-MM-DDTHH:mm:ss')
  }
}

const updateAnalysisSampleExport = (analysisSample, newExportsValue) => {
  return {
    ...analysisSample,
    Export: newExportsValue
  }
}

const updateWaterAirSampleExport = (sample, newExportsValue) => {
  return {
    ...sample,
    Export: newExportsValue
  }
}

export default {
  async updateSelectedExportItem(navigationSelectedItem, projectId, tableData, selectedRows, store) {
    switch (navigationSelectedItem) {
      case 'Measurementpoints':
        return this.updateMeasurementPoints(projectId, tableData, selectedRows, store)
      case 'AnalysisSamples':
        return this.updateAnalysisSamples(projectId, tableData, selectedRows, store)
      case 'WaterSamples':
        return this.updateWaterAirSamples('Water', projectId, tableData, selectedRows, store)
      case 'AirSamples':
        return this.updateWaterAirSamples('Air', projectId, tableData, selectedRows, store)
      default:
        return null
    }
  },
  async updateMeasurementPoints(projectId, tableData, selectedRows, store) {
    const changedMeasurementPoints = []
    const projectMeasurementPoints = await store.dispatch('getMeasurementPoints', { projectId })

    const selectedRowsHasString = selectedRows.some(row => typeof row === 'string')
    if (selectedRowsHasString) return false

    // Checks every measurement point in project and checks if the matching table measurement point has a different MpInExports value
    for (const projectMeasurementPoint of projectMeasurementPoints) {
      const matchingMeasurementPoint = tableData.find((measurementPoint) => measurementPoint.MpGuid === projectMeasurementPoint.MpGuid)
      if (!matchingMeasurementPoint) continue

      const newExportsValue = selectedRows.some((row) => row.MpGuid === projectMeasurementPoint.MpGuid) ? 'true' : 'false'

      if (projectMeasurementPoint.MpInExports !== newExportsValue) {
        const updatedMeasurementPoint = updateMeasurementPointExport(projectMeasurementPoint, newExportsValue)
        changedMeasurementPoints.push(updatedMeasurementPoint)
      }
    }

    // Send changed measurement points to the server
    const result = await store.dispatch('setMeasurementPoints', { projectId, measurementPoints: changedMeasurementPoints })
    return result
  },
  async updateAnalysisSamples(projectId, tableData, selectedRows, store) {
    const changedAnalysisSamples = []
    const projectAnalysisSamples = await store.dispatch('getAnalysisSampleReportWS', { projectId })

    for (const analysisSample of projectAnalysisSamples) {
      const matchingTableSample = tableData.find((tableSample) => tableSample.AnGUID === analysisSample.AnGUID)
      if (!matchingTableSample) continue

      const newExportsValue = selectedRows.some((row) => row.AnGUID === analysisSample.AnGUID)

      if (analysisSample.Export !== newExportsValue) {
        const updatedAnalysisSample = updateAnalysisSampleExport(analysisSample, newExportsValue)
        changedAnalysisSamples.push(updatedAnalysisSample)
      }
    }

    await store.dispatch('setAnalysisSampleReportWS', { changedAnalysisSamples })
    const result = await store.dispatch('getAnalysisSampleReportWS', { projectId })
    return result
  },
  async updateWaterAirSamples(sampleType, projectId, tableData, selectedRows, store) {
    const waterAirSamples = []
    const projectSamples = await store.dispatch(`getWaterSamplesReportWS`, { projectId })
    for (const sample of projectSamples) {
      const matchingTableSample = tableData.find((tableSample) => tableSample.Id === sample.Id)
      if (!matchingTableSample) continue

      const newExportsValue = selectedRows.some((row) => row.Id === sample.Id)

      if (sample.Export !== newExportsValue) {
        const updatedSample = updateWaterAirSampleExport(sample, newExportsValue)
        waterAirSamples.push(updatedSample)
      }
    }
    await store.dispatch('setWaterSamplesReportWS', { waterAirSamples })
    const result = await store.dispatch('getWaterSamplesReportWS', { projectId })
    return result.filter(item => sampleType === 'Water' ? (item.Matrix !== 'OL' && item.Matrix !== 'BL') : (item.Matrix === 'OL' || item.Matrix === 'BL'))
  }
}
