<template>
  <div class="export-content-body">
    <div class="export-completed">
      <i class="fa-regular fa-party-horn icon"/>
      <p class="export-completed-title">{{ $t('export.label.emailSend') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareComplete'
}
</script>

<style lang="less" scoped>
.export-content-body {
  width: 90vw;
  height: calc(80vh - 128px);
  .export-completed {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    .icon {
      color: #000;
      text-align: center;
      font-size: 75px;
      font-weight: 900;
    }
    .export-completed-title {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
