<template>
  <div id="searchWrapper">
    <template>
      <span v-show="query !== ''" class="glyphicons glyphicons-remove" @click="reset" />
      <span v-show="query === ''" class="glyphicons glyphicons-search" />
    </template>
    <!-- the input field -->
    <input
      v-model="query"
      class="searchComponent"
      type="text"
      :placeholder="$t('message.Type_to_search')"
      autocomplete="on"
      @keydown="hit"
      v-on:keydown.enter="enter" />
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Search',
  props: {
    immediate: {
      type: Boolean,
      required: false,
      default: true
    },
    activeSearchFilter: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      query: this.activeSearchFilter
    }
  },
  created() {
    this.hit = this.immediate ? _.debounce(this.hit, 200) : () => { }
  },

  methods: {
    hit() {
      this.$emit('search', this.query)
    },
    reset() {
      this.query = ''
      if (this.immediate) {
        this.$emit('search', this.query)
      }
    },
    enter() {
      this.$emit('search', this.query)
    }
  }
}
</script>
<style lang="less">
#searchWrapper {
  width: 300px;

  i.fa {
    position: absolute;
    top: 10px;
    left: 8px;
  }

  span.glyphicons {
    position: absolute;
    top: 3px;
    right: 2px;
  }

  span.glyphicons-search {
    color: gray;
  }

  input,
  input:focus,
  input:active {
    line-height: 200%;
    max-width: 360px;
    width: 100%;
    padding-right: 30px;
    padding-left: 8px;
    padding-bottom: 2px;
    outline: none;
    border: 1px solid silver;
    height: 30px;
  }

  input:focus {
    border: 1px solid rgb(132, 189, 96);
  }

  ul {
    list-style-type: none;
    line-height: 150%;
    border: 1px solid rgb(132, 189, 96);
    overflow: scroll;
    max-height: 400px;
    padding: 0;
    background-color: white;
    z-index: 100;
    width: 360px;
    position: absolute;
  }

  li {
    padding: 4px;
    cursor: default;
    overflow-wrap: break-word;
  }

  li:nth-child(even) {
    background-color: #f3f3f3;
  }

  li:hover,
  li.active {
    background-color: rgb(132, 189, 96);
    color: white;
  }

  @media (max-width: 415px) {
    ul {
      width: 100%;
    }
  }
}
</style>
