<template>
  <div class="export-content-body">
    <SelectExportSideBar
     :exportItems="this.exportItems"
     :selectedExportItem="this.selectedExportItem"
     @setSelectedExportItem="setSelectedExportItem"
     @setSelectedExportConfig="setSelectedExportConfig"
     @enableSelectExportButtons="enableSelectExportButtons"/>
    <SelectExportInformation
      :exportItems="this.exportItems"
      :selectedExportItem="this.selectedExportItem"
      :exportButtonsAreEnabled="this.exportButtonsAreEnabled"
      @setNextPage="setNextPage"
      @goNextPage="goNextPage"/>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import SelectExportSideBar from './selectExportSideBar.vue'
import SelectExportInformation from './selectExportInformation.vue'

export default {
  name: 'SelectExportType',
  components: {
    SelectExportSideBar,
    SelectExportInformation
  },
  props: {
    exportItems: {
      type: Array,
      required: true
    },
    exportButtonsAreEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedExportItem: ''
    }
  },
  created() {
    this.selectedExportItem = this.exportItems[0].group
    this.$emit('setSelectedExportConfig', this.selectedExportItem)
  },
  computed: {

  },
  methods: {
    setNextPage({ nextComponent, nextFooter }) {
      this.$emit('setNextPage', { nextComponent, nextFooter })
    },
    goNextPage() {
      this.$emit('goNextPage')
    },
    setSelectedExportItem(value) {
      this.selectedExportItem = value
    },
    setSelectedExportConfig(value) {
      this.$emit('setSelectedExportConfig', value)
    },
    enableSelectExportButtons(value) {
      this.$emit('enableSelectExportButtons', value)
    }
  }
}
</script>

<style lang="less" scoped>
.export-content-body {
  display: flex;
  width: 90vw;
  height: calc(80vh - 66px);
}
</style>
