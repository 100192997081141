<template>
  <div class="footer-button-container">
    <p class="popup-footer-error">{{ errorMessage }}</p>
    <button class="popup-footer-button secondary-button" :disabled="isCreatingExport" @click="handleFooterButtonClick()">
      {{ $t('export.label.share') }}
      <i class="fa-regular fa-envelope"></i>
    </button>
    <button class="popup-footer-button primary-button" :disabled="isCreatingExport" @click.prevent="downloadExportFile">
      {{ $t('other.download') }}
      <i class="fa-regular fa-cloud-arrow-down"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CreateExportFooter',
  props: {
    exportProjects: {
      type: Array,
      required: true
    },
    isCreatingExport: {
      type: Boolean,
      required: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    handleFooterButtonClick() {
      this.$emit('setNextPage', { nextComponent: 'ShareExport', nextFooter: 'ShareExportFooter' })
      this.$emit('goNextPage')
    },
    downloadExportFile() {
      this.$store.dispatch('downloadExportFiles', this.exportProjects)
    }
  }
}
</script>
