<template>
  <ExportDynamicTable
    :tableData="getSelectedNavigationTableData"
    :tableDataIdentifier="tableDataIdentifier"
    :tableName="tableName"
    :tableColumns="tableColumns"
    :projectId="projectId"
    :language="language"
    :selectedRowKeys="getSelectedNavigationSelectedRows"
    :isValidRequest="isValidRequest"
    :navigationSelectedItem="navigationSelectedItem"
    @tableContentUpdated="setAnalysisSamplesTableContent"
    @isLoading="isLoading"/>
</template>

<script>
import ExportDynamicTable from '@/components/export/tables/exportDynamicTable'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'

export default {
  name: 'AnalysisSamplesExportTable',
  components: {
    ExportDynamicTable
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    tableDataPerSampleType: {
      type: Array,
      required: true
    },
    navigationSelectedItem: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      tableColumns: [
        {
          caption: this.$t('AnalysesResults.SampleName'),
          dataFieldKey: 'SampleName',
          dataType: 'string',
          visible: true,
          validations: {
            dxLookup: false
          }
        },
        {
          caption: this.$t('AnalysesResults.SampleDate'),
          dataFieldKey: 'Date',
          dataType: 'date',
          visible: true,
          validations: {
            dxLookup: false
          }
        },
        {
          caption: this.$t('AnalysesResults.SampleFrom'),
          dataFieldKey: 'From',
          dataType: 'number',
          visible: true,
          validations: {
            dxLookup: false
          }
        },
        {
          caption: this.$t('AnalysesResults.SampleTo'),
          dataFieldKey: 'To',
          dataType: 'number',
          visible: true,
          validations: {
            dxLookup: false
          }
        },
        {
          caption: this.$t('AnalysesResults.SampleTo'),
          dataFieldKey: 'Export',
          dataType: 'boolean',
          visible: false,
          validations: {
            dxLookup: false
          }
        },
        {
          caption: this.$t('AnalysesResults.SampleMatrix'),
          dataFieldKey: 'Matrix',
          dataType: 'string',
          visible: true,
          validations: {
            dxLookup: true,
            dxLookupValidationProperties: {
              displayProperty: 'description',
              options: [
                {
                  key: 'AW',
                  description: this.$t('SuggestedSamples.MatrixAW')
                },
                {
                  key: 'BL',
                  description: this.$t('SuggestedSamples.MatrixBL')
                },
                {
                  key: 'DW',
                  description: this.$t('SuggestedSamples.MatrixDW')
                },
                {
                  key: 'GR',
                  description: this.$t('SuggestedSamples.MatrixGR')
                },
                {
                  key: 'GW',
                  description: this.$t('SuggestedSamples.MatrixGW')
                },
                {
                  key: 'OL',
                  description: this.$t('SuggestedSamples.MatrixOL')
                },
                {
                  key: 'OW',
                  description: this.$t('SuggestedSamples.MatrixOW')
                },
                {
                  key: 'OV',
                  description: this.$t('SuggestedSamples.MatrixOV')
                },
                {
                  key: 'SW',
                  description: this.$t('SuggestedSamples.MatrixSW')
                },
                {
                  key: 'ST',
                  description: this.$t('SuggestedSamples.MatrixST')
                },
                {
                  key: 'UW',
                  description: this.$t('SuggestedSamples.MatrixUW')
                },
                {
                  key: 'SL',
                  description: this.$t('SuggestedSamples.MatrixSL')
                },
                {
                  key: '14',
                  description: this.$t('SuggestedSamples.Matrix14')
                }
              ],
              valueProperty: 'key'
            }
          }
        }
      ],
      tableDataIdentifier: 'AnGUID',
      tableName: 'tblAnalysisSamples'
    }
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    language() {
      return Cookie.get('language')
    },
    getSelectedNavigationTableData() {
      return this.getAnalysisSamplesTableData().tableData
    },
    getSelectedNavigationSelectedRows() {
      return this.getAnalysisSamplesTableData().selectedRows
    }
  },
  methods: {
    getAnalysisSamplesTableData() {
      return this.tableDataPerSampleType.find(item => item.name === this.navigationSelectedItem)
    },
    setAnalysisSamplesTableContent(analysisSamplesTableContent) {
      this.$emit('tableContentUpdated', analysisSamplesTableContent)
    },
    isLoading(isLoading) {
      this.$emit('isLoading', isLoading)
    }
  }
}
</script>
