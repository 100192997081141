<template>
  <div class="selected-export-options-in-export-boxes">
    <i :class="getOptionNameIcon()"></i> {{ this.$t(`label.${this.optionName}`) }}
  </div>
</template>

<script>
import '@/assets/less/colors.less'

export default {
  name: 'SelectExportOption',
  props: {
    optionName: {
      type: String,
      required: true
    },
    selectedExportObject: {
      type: Object,
      required: true
    }
  },
  methods: {
    getOptionNameIcon() {
      switch (this.optionName) {
        case 'Measurementpoints':
          return 'fa-regular fa-bore-hole'
        case 'AnalysisSamples':
          return 'fa-regular fa-prescription-bottle'
        case 'WaterSamples':
          return 'fa-regular fa-water'
        case 'AirSamples':
          return 'fa-regular fa-wind'
        default:
          return 'fa-regular fa-question'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.selected-export-options-in-export-boxes {
  display: flex;
  padding: 10px 17px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border: 1px @gray300 solid;
  border-radius: 113px;
}
</style>
