<template>
  <div class="export-content-body">
    <div class="table-navigation-container">
      <div class="title">{{ projectCode }}</div>
      <div class="navigation">
        <ChangeExportTableSelectionNavigation
          v-for="navigationItem in navigationItems"
          :key="navigationItem.label"
          :navigationItem="navigationItem"
          :navigationSelectedItem="navigationSelectedItem"
          :selectedExportObjectKeys="getSelectedExportObjectKeys"
          :tableDataSummaryBySampleType="getTableDataSummaryBySampleType"
          :exportSpecificOptions="exportSpecificOptions"
          :tableContent="tableContent"
          @isLoading="setIsLoading"
          @tableNavigationUpdated="updateTableNavigation"/>
      </div>
      <div class="button-container">
        <button class="column-button" @click="updateSelectedExportItem">
          <i class="fa-regular fa-floppy-disk"></i>
        </button>
      </div>
    </div>
    <div v-if="measurementPointsData.tableData.length > 0" class="selected-export-item-data-table-overview">
      <component
        :is="selectedNavigationItem"
        :projectId="projectId"
        :tableDataPerSampleType="tableDataPerSampleType"
        :navigationSelectedItem="navigationSelectedItem"
        @tableContentUpdated="setTableContent"
        @isLoading="setIsLoading"
      />
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import ChangeExportTableSelectionNavigation from '@/components/export/changeExportDataSelection/changeExportTableSelectionNavigation.vue'
import MeasurementPointsExportTable from '../tables/measurementPointsExportTable.vue'
import AnalysisSamplesExportTable from '../tables/analysisSamplesExportTable.vue'
import WaterSamplesExportTable from '../tables/waterSamplesExportTable.vue'
import AirSamplesExportTable from '../tables/airSamplesExportTable.vue'
import NavigationSelectedItemState from '../../../static/NavigationSelectedItemState'

import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import stringUtils from '@/utils/stringUtils'
import updateSelectedExportItem from '@/utils/export/updateSelectedExportItem'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'ChangeExportDataSelection',
  components: {
    ChangeExportTableSelectionNavigation,
    MeasurementPointsExportTable,
    AnalysisSamplesExportTable,
    WaterSamplesExportTable,
    AirSamplesExportTable
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    exportItems: {
      type: Array,
      required: false
    },
    selectedExportConfig: {
      type: Object,
      required: false
    },
    tableContent: {
      required: true
    },
    measurementPointsData: {
      type: Object,
      required: true
    },
    projectCode: {
      type: String,
      required: false
    },
    excludeDataFieldKeys: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      tableDataPerSampleType: [],
      navigationSelectedItem: '',
      navigationItems: [
        {
          id: NavigationSelectedItemState.Measurementpoints,
          label: this.$t('label.Measurementpoints'),
          icon: 'fa-regular fa-bore-hole'
        },
        {
          id: NavigationSelectedItemState.AnalysisSamples,
          label: this.$t('label.AnalysisSamples'),
          icon: 'fa-regular fa-prescription-bottle'
        },
        {
          id: NavigationSelectedItemState.WaterSamples,
          label: this.$t('label.WaterSamples'),
          icon: 'fa-regular fa-water'
        },
        {
          id: NavigationSelectedItemState.AirSamples,
          label: this.$t('label.AirSamples'),
          icon: 'fa-regular fa-wind'
        }
      ],
      exportSpecificOptions: [],
      isLoading: false
    }
  },
  async created() {
    await this.setCurrentProject()
    this.navigationSelectedItem = NavigationSelectedItemState.Measurementpoints
    await this.fetchExportData()
  },
  watch: {
    isLoading() {
      this.$emit('isLoading', this.isLoading)
    }
  },
  computed: {
    workingObject() {
      return this.$store.state.workingObject
    },
    selectedNavigationItem() {
      switch (this.navigationSelectedItem) {
        case NavigationSelectedItemState.Measurementpoints:
          return 'MeasurementPointsExportTable'
        case NavigationSelectedItemState.AnalysisSamples:
          return 'AnalysisSamplesExportTable'
        case NavigationSelectedItemState.WaterSamples:
          return 'WaterSamplesExportTable'
        case NavigationSelectedItemState.AirSamples:
          return 'AirSamplesExportTable'
        default:
          return ''
      }
    },
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    getSelectedExportObjectKeys() {
      const exportObjectKeys = Object.keys(this.selectedExportConfig.fieldValue)
      const selectedOptions = this.navigationItems.filter(optionName => exportObjectKeys.includes(optionName.id))
      return selectedOptions
    },
    getTableDataSummaryBySampleType() {
      return this.tableDataPerSampleType.map(tableDataSampleType => ({
        name: tableDataSampleType.name,
        tableDataLength: tableDataSampleType.tableData.length,
        selectedRowsLength: tableDataSampleType.selectedRows.length
      }))
    }
  },
  methods: {
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return false
      }
      await this.$store.dispatch('fetchProject', {
        PrID: this.projectId
      })
      const project = this.$store.state.route.params.projectId

      if (!project && !this.projectId) {
        TiPopup.popup(this.$t('message.projectNotFound'))
      }
    },
    async fetchExportData() {
      this.isLoading = true
      const selectedOptions = this.selectedExportConfig.fieldValue

      this.exportSpecificOptions = Object.values(NavigationSelectedItemState).filter(state => {
        return Object.keys(selectedOptions).includes(state)
      })

      if (!this.exportSpecificOptions.length > 0) return

      this.tableDataPerSampleType = []

      await Promise.all(this.exportSpecificOptions.map(async exportSpecificOption => {
        let tableData
        let selectedRows

        if (exportSpecificOption === NavigationSelectedItemState.Measurementpoints) {
          tableData = this.measurementPointsData.tableData
          selectedRows = this.measurementPointsData.selectedRows
        } else {
          tableData = await this.getTableData(exportSpecificOption)
          selectedRows = await this.getSelectedRows(exportSpecificOption, tableData)
        }

        // Removes columns that are not needed
        if (exportSpecificOption !== NavigationSelectedItemState.Measurementpoints) {
          tableData.forEach(row => {
            Object.keys(row).forEach(key => {
              if (this.excludeDataFieldKeys.find(excludeArray => excludeArray.name === exportSpecificOption).keys.includes(key)) return
              delete row[key]
            })
          })
        }

        this.tableDataPerSampleType.push({
          name: exportSpecificOption,
          tableData,
          selectedRows
        })
      }))

      this.isLoading = false
      this.$emit('navigatedToSelectedItem', this.navigationSelectedItem)
      this.$emit('tableContentUpdated', { name: 'Measurementpoints', tableData: this.measurementPointsData.tableData, selectedRows: this.measurementPointsData.selectedRows })
    },
    async getTableData(selectedItemState) {
      let dataAction
      if (selectedItemState === NavigationSelectedItemState.AnalysisSamples) {
        dataAction = 'getAnalysisSampleReportWS'
      }
      if (selectedItemState === NavigationSelectedItemState.WaterSamples || selectedItemState === NavigationSelectedItemState.AirSamples) {
        dataAction = 'getWaterSamplesReportWS'
      }
      const result = await this.$store.dispatch(dataAction, {
        projectId: this.projectId
      })
      if (result === false) {
        this.handleErrorResponse()
        return []
      }
      if (selectedItemState === NavigationSelectedItemState.WaterSamples) {
        return result.filter(waterSample => waterSample.Matrix !== 'OL' && waterSample.Matrix !== 'BL')
      }
      if (selectedItemState === NavigationSelectedItemState.AirSamples) {
        return result.filter(waterSample => waterSample.Matrix === 'OL' || waterSample.Matrix === 'BL')
      }
      return result
    },
    getSelectedRows(selectedItemState, tableData) {
      if (selectedItemState === NavigationSelectedItemState.AnalysisSamples) {
        return this.getSelectedAnalysisSamplesGuids(tableData)
      }
      if (selectedItemState === NavigationSelectedItemState.WaterSamples || selectedItemState === NavigationSelectedItemState.AirSamples) {
        return this.getSelectedWaterAirSamplesIds(tableData)
      }
    },
    getSelectedAnalysisSamplesGuids(tableData) {
      return tableData.filter(row => row.Export === true).map(row => row.AnGUID)
    },
    getSelectedWaterAirSamplesIds(tableData) {
      return tableData.filter(row => row.Export === true).map(row => row.Id)
    },
    setTableContent(tableContent) {
      this.$emit('tableContentUpdated', tableContent)
    },
    async updateTableNavigation(selectedItem) {
      this.$emit('navigatedToSelectedItem', selectedItem.id)
      if (!this.tableContent.tableData || this.tableContent.tableData.length === 0) {
        this.navigationSelectedItem = selectedItem.id
        return
      }
      this.isLoading = true
      await this.updateSelectedExportItem()
      this.isLoading = false
      this.navigationSelectedItem = selectedItem.id
    },
    async updateSelectedExportItem() {
      if (!this.tableContent.tableData || this.tableContent.tableData.length === 0) return
      this.isLoading = true
      const result = await updateSelectedExportItem.updateSelectedExportItem(
        this.navigationSelectedItem,
        this.projectId,
        this.tableContent.tableData,
        this.tableContent.selectedRows,
        this.$store)
      this.isLoading = false
      if (!result) return

      const tableDataSampleType = this.tableDataPerSampleType.find(tableDataSampleType => tableDataSampleType.name === this.navigationSelectedItem)
      if (!tableDataSampleType) return
      if (this.navigationSelectedItem === 'Measurementpoints') {
        tableDataSampleType.tableData = result
        tableDataSampleType.selectedRows = result.filter(row => row.MpInExports === 'true').map(row => row.MpGuid)
        this.tableContent.tableData = []
        this.$emit('setMeasurementPointsData', { tableData: result, selectedRows: tableDataSampleType.selectedRows })
        return
      }

      const selectedRows = this.getSelectedRows(this.navigationSelectedItem, result)
      tableDataSampleType.tableData = result
      tableDataSampleType.selectedRows = selectedRows
      this.tableContent.tableData = []
    },
    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },
    handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>

<style lang="less" scoped>
.export-content-body {
  width: 90vw;
  height: calc(80vh - 128px);
  padding: 0px 32px;
}
.table-navigation-container {
  display: flex;
  padding: 10px 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .navigation {
    display: flex;
    justify-content: center;
    border: 1px solid @gray400;
    border-radius: 4px;
  }
  .button-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .column-button {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: @greenTerra600;
    color: white;
    &:hover {
      background-color: @greenTerra700;
    }
    &:disabled {
      background-color: @gray200;
      cursor: not-allowed;
    }
  }
}
}
.selected-export-item-data-table-overview {
  display: flex;
  max-height: calc(95% - 48px);
  max-width: 100%;
  height: 100%;
  width: 100%;
}
</style>
