<template>
  <div class="export-content-body">
    <div class="container">
      <div class="header">
        <div class="title-bar">
          <div class="title">{{ getProjectName }}</div>
        </div>
      </div>
      <div class="export-progress-container">
        <div class="export-progress-text">{{ this.$t('export.label.exportProgress')}}
          <div v-if="!isExportLoadingActive" class="loading-text">100%</div>
          <div v-else class="loading-animation">
            <span class="dot">.</span>
            <span class="dot">.</span>
            <span class="dot">.</span>
          </div>
        </div>
        <BaseLoadingBar :isLoadingActive="isExportLoadingActive" />
      </div>
      <div class="export-log-container">
        <div v-if="!isExportLoadingActive && !showExportLog" class="export-log-container-no-export">
          <p class="export-log-container-no-export-title">{{ $t('export.label.noExportLogAvailable') }}</p>
        </div>
        <div class="export-log-wrapper">
          <div v-if="!isExportLoadingActive && showExportLog" class="export-log" v-html="exportLog"/>
          <div class="export-log-button-container">
            <button v-if="!isExportLoadingActive && showExportLog" class="export-log-button-container-button" @click="downloadExportLog()">
              <p class="export-log-button-text">Export Log</p>
              <i class="fa-regular fa-arrow-up-right-from-square"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import BaseLoadingBar from '@/components/boxes/base-components/baseLoadingBar.vue'

export default {
  name: 'CreateExport',
  components: {
    BaseLoadingBar
  },
  props: {
    exportCreated: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isExportLoadingActive: false,
      showExportLog: false,
      exportLog: ''
    }
  },
  async created() {
    if (!this.exportCreated) {
      await this.setExportFiles()
    }

    this.exportLog = this.getExportLog
    this.showExportLog = this.getExportProjects[0].selectedExportConfig.name !== 'TerraIndex'
    this.isExportLoadingActive = false
  },
  computed: {
    getExportProjects() {
      return this.$store.getters.getExportInformationExportProjects
    },
    getProjectName() {
      return this.getExportProjects[0].PrCode
    },
    getExportLog() {
      return this.getExportProjects[0].exportResponse.ExportLog
    }
  },
  methods: {
    async setExportFiles() {
      if (this.getExportProjects.length <= 0) return

      this.$emit('creatingExportChanged', true)
      this.isExportLoadingActive = true
      await this.$store.dispatch('setExportProjects', this.getExportProjects)

      this.$emit('creatingExportChanged', false)
      this.isExportLoadingActive = false

      if (this.getExportLog === null) {
        this.showExportLog = false
        return
      }

      this.exportLog = this.getExportLog
      this.showExportLog = true

      this.getExportProjects.forEach(project => {
        if (project.exportResponse.ResultCode !== 'Export_Succes') {
          this.$emit('errorOccurred', this.$t('export.messages.exportFailed'))
          this.$emit('creatingExportChanged', true)
        }
      })
    },
    downloadExportLog() {
      let blob = new Blob([this.exportLog], { type: 'text/html' })
      let downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = `${this.getProjectName}_${this.getExportProjects[0].selectedExportConfig.name}_log.html`
      downloadLink.click()
    }
  }
}
</script>

<style lang="less" scoped>
.export-content-body {
  width: 90vw;
  height: calc(80vh - 128px);
  .container {
    width: 100%;
    height: 100%;
    padding: 10px 32px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .header {
      align-self: stretch;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      .title-bar {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .title {
          color: @gray800;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    .export-progress-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      .export-progress-text {
        display: flex;
        align-items: center;
        color: @gray600;
        font-size: 16px;
        font-weight: 500;
      }
      .loading-text {
        margin-left: 3px;
        color: @gray600;
        font-size: 16px;
        font-weight: 500;
      }
      .loading-animation {
        margin-left: 3px;
        .dot {
          display: inline-block;
          animation: wave 1.3s linear infinite;
          &:nth-child(2) {
            animation-delay: -1.1s;
          }
          &:nth-child(3) {
            animation-delay: -0.9s;
          }
        }
        @keyframes wave {
          0%, 60%, 100% {
            transform: initial;
          }
          30% {
            transform: translateY(-15px);
          }
        }
      }
    }
    .export-log-container {
      height: calc(100% - 128px);
      max-height: 100%;
      width: 100%;
      margin-top: 16px;
      overflow: auto;
      gap: 8px;
      .export-log-container-no-export {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .export-log-container-no-export-title {
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          color: #000;
        }
      }
      .export-log-wrapper {
        display: flex;
        flex-direction: row;
        .export-log {
          width: 100%;
        }
        .export-log-button-container {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          .export-log-button-container-button {
            display: flex;
            align-items: center;
            padding: 6px 18px;
            gap: 8px;
            border-radius: 4px;
            border: 1px solid @gray400;
            background: white;
            .export-log-button-text {
              margin-bottom: 0px;
              color: @gray700;
              font-size: 14px;
              font-weight: 600;
              text-decoration: none;
              white-space: nowrap;
            }
            &:hover {
              background: @gray100;
            }
          }
        }
      }
    }
  }
}

</style>
