import { render, staticRenderFns } from "./measurementPointsExportTable.vue?vue&type=template&id=5a190ffb&"
import script from "./measurementPointsExportTable.vue?vue&type=script&lang=js&"
export * from "./measurementPointsExportTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports