<template>
  <div>
    <app-header />
    <div class="clearfix"></div>
    <div class="duplicate alert alert-success" role="alert" style="display: none"></div>
    <div class="search">
      <search ref="search-component" :activeSearchFilter="activeSearchFilter" @search="search" />
    </div>
    <div>
      <h2>
        {{ $t('tableTitles.MyProjects') }}
        <i v-if="myProjects.length > 0" :title="$t('tooltips.unpinAllProjects')" style="float: right; padding-right: 23px; cursor: pointer" class="glyphicons glyphicon-move" @click="unpinAllProjects"></i>
      </h2>
    </div>
    <div class="project-table" v-bind:class="{ displayNone: query }">
      <v-client-table :data="projectsFilteredByQuery" :columns="columns" :options="myProjectsOptions" class="selectableTable" @row-click="options.onRowClick">
        <div slot="selected" slot-scope="props" class="unselectable checkBoxWrapper" v-on:click="toggleSelected('myProjects', props.row)">
          <input type="checkbox" />
          <label v-bind:class="{ checked: props.row.check }">
            <span></span>
          </label>
        </div>
        <div slot="PrDateContentLastChanged" slot-scope="props">
          <span>{{ options.formatValue(props.row, 'PrDateContentLastChanged') }}</span>
        </div>
        <div slot="pinned" slot-scope="props">
          <span class="glyphicons glyphicons-pushpin" :class="{ unpin: props.row.pinned }" :data-id="props.row.PrID" @click="pinUnPinProject('myProjects', props.row, $event)"></span>
        </div>
      </v-client-table>
    </div>

    <h2>{{ $t('tableTitles.AllProjects') }}</h2>

    <div class="project-table" v-bind:class="{ displayNone: !query }">
      <v-client-table :data="projects" :columns="columns" :options="options" class="selectableTable" @row-click="options.onRowClick">
        <div slot="selected" slot-scope="props">
          <input type="checkbox" />
          <label v-bind:class="{ checked: props.row.check }" v-on:click="toggleSelected('projects', props.row)">
            <span></span>
          </label>
        </div>
        <div slot="PrDateContentLastChanged" slot-scope="props">
          <span>{{ options.formatValue(props.row, 'PrDateContentLastChanged') }}</span>
        </div>
        <div slot="PrPhaseCode" slot-scope="props">
          <span>{{ props.row.PrPhaseCode }}</span>
        </div>
        <div slot="pinned" slot-scope="props">
          <span class="glyphicons glyphicons-pushpin" :class="{ unpin: props.row.pinned }" :data-id="props.row.PrID" @click="pinUnPinProject('projects', props.row, $event)"></span>
        </div>
      </v-client-table>
    </div>

    <div v-show="projects.length >= 30" class="text-center m-b-sm" v-bind:class="{ displayNone: !query }">
      <a href="javascript:;" @click="loadmore">
        <strong>{{ $t('other.loadmore', { count: '' }) }}</strong>
      </a>
    </div>

    <div id="modalNewProject" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalNewProjectLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            <h4 id="modalNewProjectLabel" class="modal-title">
              {{ $t('project.CreateNewProject') }}
            </h4>
          </div>
          <div class="modal-body">
            <p class="field-name">{{ $t('columnTitles.ProjectCode') }}</p>
            <input id="txtNewProjectCode" v-model="newProjectCode" type="text" class="form-control" @keypress="checkSubmit" @keyup="normalizeProjectCode" />

            <p class="field-name">{{ $t('label.fieldTemplate') }}</p>
            <div class="has-feedback">
              <select v-model="newProjectFieldTemplate" class="form-control">
                <option v-for="template in fieldTemplates" v-bind:key="template.id" v-bind:value="template.GcCode">
                  {{ template.GcDescription }}
                </option>
              </select>
            </div>

            <p class="field-name">{{ $t('label.system') }}</p>
            <div class="has-feedback">
              <select v-model="newProjectCoordinateSystem" class="form-control">
                <option v-for="system in orderdCoordinateSystems" v-bind:key="system.GcCode" v-bind:value="system.GcCode">
                  {{ system.GcDescription }}
                </option>
              </select>
            </div>

            <p class="error">{{ prValidationError }}</p>
          </div>
          <div class="modal-footer">
            <button id="btnCreateProject" type="button" class="btn" @click="saveNewProject">
              {{ $t('project.CreateButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <PincodeExpiredNotificationModal :visible="showPincodeExpiredNotification" :pincode-expiration-text="pincodeExpirationText" @modalDismissed="onPincodeExpiredNotificationModalDismissed" />
    <app-footer :selections="selections" />
    <nv-loading :show="loading"></nv-loading>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" :PrID="this.projectId" :PrCode="this.projectCode" @closeButtonClicked="toggleExportPopup" />
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import $ from 'jquery'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import dateFormatter from '@/utils/dateFormatter'
import projectionUtils from '@/utils/projectionUtil'
import Popup from '@/utils/popup'
import config from '@/configurations/config'
import nvLoading from '@/components/_shared/loading.vue'
import search from '@/components/_shared/search.vue'
import sortUtils from '@/utils/sortUtils.js'
import PincodeExpiredNotificationModal from '@/azure-auth/PincodeExpiredNotificationModal.vue'
import moment from 'moment'
import { ProjectService } from '@/services/DataWS/projectService'
import { ProjectsOverviewService } from '@/services/DataWS/projectsOverviewService'
import { CodeListService } from '@/services/DataWS/codeListService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'
import cookie from 'vue-cookie'

const codeListService = new CodeListService()
const projectService = new ProjectService()
const projectsOverviewService = new ProjectsOverviewService()

const popup = Popup[config.platform].default

const optionData = {
  SearchFilter: '',
  PageSize: 30,
  LastRowNR: 0,
  OrderPreviewBy: 1
}

let self

export default {
  name: 'projects',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    search,
    PincodeExpiredNotificationModal,
    ExportProjectPopup
  },
  data() {
    const momentDisplayFormat = 'DD-MM-YYYY HH:mm'
    const options = {
      target: 'Projects',
      headings: {
        selected: '',
        PrCode: this.$t('columnTitles.ProjectCode'),
        PrName: this.$t('columnTitles.ProjectName'),
        PrLocCity: this.$t('columnTitles.ProjectPlace'),
        PrDateContentLastChanged: this.$t('columnTitles.ProjectDate'),
        PrCodeName: this.$t('columnTitles.ProjectCodeName'),
        pinned: ''
      },
      columnsDisplay: {
        PrCode: 'not_mobile',
        PrName: 'not_mobile',
        PrLocCity: 'not_mobile',
        PrDateContentLastChanged: 'not_mobile',
        PrCodeName: 'max_mobile'
      },
      columnsClasses: {
        selected: 'prCol_selected',
        PrCode: 'prCol_PrCode',
        PrName: 'prCol_PrName',
        PrLocCity: 'prCol_PrLocCity',
        PrDateContentLastChanged: 'prCol_PrDateContentLastChanged',
        PrCodeName: 'prCol_PrCodeName',
        PrPhaseCode: 'prCol_PrPhaseCode',
        pinned: 'prCol_pinned'
      },
      filterable: false,
      perPage: 1000,
      pagination: {
        dropdown: false
      },
      perPageValues: [1000], // hacky way to not show dropdown
      onRowClick: (data, e) => {
        e = e || window.event || data.event
        if (
          e.target.tagName.toUpperCase() === 'SPAN' ||
          e.target.tagName.toUpperCase() === 'LABEL' ||
          e.target.tagName.toUpperCase() === 'INPUT' ||
          e.target.tagName.toUpperCase() === 'I' ||
          e.target.classList.contains('unselectable') ||
          e.target.getAttribute('data-index') === '0'
        ) {
          return
        }
        self.$router.push({
          name: 'project',
          params: { projectId: data.row.PrID }
        })
      },
      formatValue: (row, column) => {
        dateFormatter.ready()
        return dateFormatter.formatIfDate(row[column], column)
      },
      customSorting: {
        PrDateContentLastChanged: function (ascending) {
          return function (a, b) {
            const unixTimeA = moment(a.PrDateContentLastChanged, momentDisplayFormat).format('x')
            const unixTimeB = moment(b.PrDateContentLastChanged, momentDisplayFormat).format('x')
            if (ascending) {
              return unixTimeA >= unixTimeB ? 1 : -1
            }
            return unixTimeA <= unixTimeB ? 1 : -1
          }
        }
      }
    }

    const myProjectsOptions = _.clone(options)
    myProjectsOptions.rowFilter = (row) => row.pinned

    return {
      showPincodeExpiredNotification: false,
      checkedRows: [],
      projects: [],
      myProjects: [],
      source: [],
      step: 1,
      query: '',
      loading: true,
      sortable: ['PrCode', 'PrName', 'PrLocCity', 'PrDateContentLastChanged', 'PrCodeName'],
      columns: ['selected', 'PrCode', 'PrName', 'PrLocCity', 'PrDateContentLastChanged', 'PrCodeName', 'pinned'],
      options,
      myProjectsOptions,
      newProjectCode: '',
      prValidationError: '',
      coordinateSystems: [],
      fieldTemplates: [],
      newProjectCoordinateSystem: window.localStorage.getItem('defaultCoordinateSystem'),
      newProjectFieldTemplate: window.localStorage.getItem('lastChoosenTemplate'),
      pincodeExpirationText: '',
      momentDisplayFormat,
      exportPopupOpen: false,
      projectId: null,
      projectCode: null
    }
  },
  computed: {
    selections() {
      return _.uniq(_.filter(this.projects, { check: true }).concat(_.filter(this.myProjects, { check: true })))
    },

    activeSearchFilter() {
      return optionData.SearchFilter
    },

    orderdCoordinateSystems() {
      return _.orderBy(this.coordinateSystems, 'GcDescription')
    },

    projectsFilteredByQuery() {
      let filteredUserProjects = this.myProjects.filter((project) => project.PrCode.toLowerCase().includes(this.query.toLowerCase()))
      return filteredUserProjects
        .sort((a, b) => sortUtils.naturalSort(
          moment(a.PrDateContentLastChanged, this.momentDisplayFormat).format('x'),
          moment(b.PrDateContentLastChanged, this.momentDisplayFormat).format('x')))
        .reverse()
    }
  },
  watch: {
    selections: {
      handler: (newVal, oldVal) => {
        const footer = self.$parent.$children.find((v) => v.$options.name === 'Footer')
        if (footer) {
          footer.onSelectionChanged(newVal)
        }
      }
    }
  },
  async created() {
    self = this
    if (cookie.get('licensenumber') === '1135') {
      this.options.columnsDisplay.PrPhaseCode = 'not_mobile'
      this.options.headings.PrPhaseCode = this.$t('columnTitles.ProjectPhase')
      this.options.columnsClasses.PrPhaseCode = 'prCol_PrPhaseCode'
      this.columns = ['selected', 'PrCode', 'PrName', 'PrLocCity', 'PrPhaseCode', 'PrDateContentLastChanged', 'PrCodeName', 'pinned']
    }

    // clear workingObject in store (and all related fields), so that when opening another project
    // we do not first load the components with data of the previous project (results in wrong
    // data that is generated once in e.g. mounted())
    this.$store.commit('clearWorkingObject')
    this.query = optionData.SearchFilter || ''
    await this.getMyProjects()
      .then((res) => {
        this.getProjectListByFilter(optionData)

        if (res.status === 200) {
          const datas = res.data
          self.parseDate(datas)
          self.addProjectCodeNameColumn(datas)
          self.myProjects = datas.map((item) => {
            Vue.set(item, 'pinned', true)
            return item
          })
          return
        }

        popup.popup(res.data && res.data.Message)
      })
      .catch(this.handlerError)

    if (this.$route.query.testpincode) {
      this.showPincodeExpiredNotification = true
    }

    var userPincodeIsExpiredOrExpiresSoon = await this.isUserPincodeExpiredOrExpiresSoon()
    if (userPincodeIsExpiredOrExpiresSoon) {
      this.showPincodeExpiredNotification = true
    }
  },
  methods: {
    async isUserPincodeExpiredOrExpiresSoon() {
      return this.$store.dispatch('getUserProfile').then((result) => {
        if (result.userData.PasswordExpiredDate && result.userData.IsGeoServerAccount) {
          const pincodeExpirationText = moment.utc(result.userData.PasswordExpiredDate).local()
          this.pincodeExpirationText = pincodeExpirationText.format('dddd, ') + pincodeExpirationText.format('LL')
          return moment().utc().add(1, 'days') >= moment.utc(result.userData.PasswordExpiredDate)
        } else {
          return false
        }
      })
    },
    onPincodeExpiredNotificationModalDismissed(event) {
      this.showPincodeExpiredNotification = false
    },
    async unpinAllProjects() {
      const promises = []
      const myProjectIDs = []
      self.loading = true
      this.myProjects.forEach((proj) => {
        myProjectIDs.push(proj.PrID)
        promises.push(projectsOverviewService.pinUnPinProject(proj.PrID, false))
      })

      await Promise.all(promises)
        .then(() => {
          myProjectIDs.forEach((id) => {
            const proj = this.projects.find((pr) => pr.PrID === id)
            if (proj) {
              proj.pinned = false
            }
          })
          this.myProjects = []
        })
        .finally(() => {
          self.loading = false
        })
    },
    getProjects() { },
    pinUnPinProject: (tableName, row, e) => {
      self.loading = true
      var PrID = row.PrID
      var project = self[tableName].find((project) => {
        return project.PrID.toString() === PrID.toString()
      })
      projectsOverviewService.pinUnPinProject(project.PrID, !project.pinned)
        .then((res) => {
          self.loading = false
          if (res.data.ResultCode !== 'Succes') {
            return popup.popup(res.data.ResultMessage || this.$t('message.UnknownError'))
          }
          Vue.set(project, 'pinned', !project.pinned)
          // push and remove pinned projects
          if (project.pinned && !_.includes(self['myProjects'], { PrID: project.PrID })) {
            self['myProjects'].push(project)
          }
          self['myProjects'] = self['myProjects'].filter((i) => i.pinned)
        })
        .catch(self.handlerError)
    },
    toggleSelected(tableName, row) {
      var PrID = row.PrID
      this.projectId = PrID.toString()
      this.projectCode = row.PrCode
      var project = this[tableName].find((project) => {
        return project.PrID === PrID
      })
      if (project) {
        var checked = project.check
        if (!checked) {
          checked = true
        } else {
          checked = false
        }
        this.$set(project, 'check', checked)
      }
    },
    toggle(evt) {
      $(evt.target).next().slideToggle()
    },
    normalizeProjectCode() {
      this.newProjectCode = this.newProjectCode.toLocaleUpperCase().replace(/ /g, '_')
    },
    checkSubmit(evt) {
      if (evt.key === 'Enter') {
        this.saveNewProject()
      }
    },
    validateProjectCode() {
      return projectService.validateProject(this.newProjectCode).then((res) => {
        const isPassed = res.body === 'true'
        if (isPassed) {
          self.prValidationError = ''
        } else {
          self.prValidationError = this.$t('project.InvalidProjectCode')
        }

        return isPassed
      })
    },
    saveNewProject() {
      if (this.newProjectCode === '' || !this.newProjectCoordinateSystem || !this.newProjectFieldTemplate) {
        this.prValidationError = this.$t('project.NewProjectRequiredFields')
        return false
      }
      window.localStorage.setItem('defaultCoordinateSystem', this.newProjectCoordinateSystem)
      window.localStorage.setItem('lastChoosenTemplate', this.newProjectFieldTemplate)
      this.loading = true

      this.validateProjectCode()
        .then((valid) => {
          if (!valid) {
            $('#modalNewProject').modal('show')
            return false
          }

          const project = {
            PrCode: self.newProjectCode,
            PrID: '1',
            PrCoordinateSystem: self.newProjectCoordinateSystem,
            PrTemplateID: self.newProjectFieldTemplate
          }
          const action = 'CREATE'
          return projectService.setProject(project, action).then((res) => {
            const projectId = res.PrID
            return projectsOverviewService.pinUnPinProject(projectId, true).then((res) => {
              self.newProjectCode = ''
              $('#modalNewProject').modal('hide')

              const path = `/project/${projectId}?new=true`
              self.$router.push(path)
              return true
            })
          })
        })
        .then(() => {
          this.loading = false
        })
        .catch((err) => {
          console.log('Catch')
          $('#modalNewProject').modal('hide')
          self.prValidationError = err
          this.loading = false
        })
    },
    checkPinState(projects) {
      for (let i = 0; i < projects.length; i++) {
        const project = projects[i]
        const myProject = _.find(self.myProjects, { PrID: project.PrID })
        if (myProject) {
          Vue.set(myProject, 'pinned', true)
          projects[i] = myProject
        } else {
          Vue.set(projects[i], 'pinned', false)
        }
      }
      return projects
    },
    loadmore() {
      if (this.step === 1) {
        return
      }
      optionData.SearchFilter = this.query
      optionData.LastRowNR = 30 * (this.step - 1)
      this.loading = true

      projectsOverviewService.getProjectListByFilter(optionData).then(
        (res) => {
          this.loading = false
          if (!res.body) {
            return
          }
          let datas = JSON.parse(res.body)
          if (!_.isArray(datas)) {
            datas = [datas]
          }

          this.parseDate(datas)
          this.addProjectCodeNameColumn(datas)
          this.checkPinState(datas)

          this.projects.push(...datas)
          this.step++
        },
        (err) => {
          this.loading = false
          console.log(err)
        }
      )
    },
    search(query) {
      if (typeof query === 'object') {
        query = query.PrCode
      }

      this.query = query
      this.step = 1
      optionData.SearchFilter = this.query
      optionData.LastRowNR = 0

      this.loading = true

      this.getProjectListByFilter(optionData)
    },
    handlerError(err) {
      // disappear the loading bar

      this.loading = false
      if (err.body && err.body.indexOf('<h1>401 Invalid Request</h1>') !== -1) {
        return
      }
      popup.popup(`${this.$t('message.Status_Code')}: ${err.status}: ${err.body}`)
    },
    getMyProjects() {
      return projectsOverviewService.getMyProjects()
    },
    getProjectListByFilter(options) {
      projectsOverviewService.getProjectListByFilter(options).then(
        (res) => {
          this.loading = false
          if (!res.body) {
            return
          }
          let origins = JSON.parse(res.body)

          if (!_.isArray(origins)) {
            origins = [origins]
          }

          this.parseDate(origins)
          this.addProjectCodeNameColumn(origins)
          this.checkPinState(origins)
          this.projects = origins
          this.step++
        },
        (err) => {
          this.loading = false
          this.handlerError(err)
        }
      )
    },
    parseDate(array) {
      dateFormatter.ready()
      array.forEach((project) => {
        project.PrDateContentLastChanged = project.PrDateContentLastChanged
          ? moment(project.PrDateContentLastChanged).format(this.momentDisplayFormat)
          : ''
      })
    },
    addProjectCodeNameColumn(array) {
      array.forEach((v) => {
        v.PrCodeName = v.PrCode
        if (v.PrName) {
          v.PrCodeName += `<br>(${v.PrName})`
        }
      })
    },
    checkSelection() {
      if (self.selections.length !== 1) {
        popup.popup(this.$t('message.Project_selection_is_required'))
        return false
      }
      return self.selections[0].PrID
    },
    importProject() {
      window.open(config.apps.import, '_self').focus()
    },
    verifyProject() {
      const projectId = this.checkSelection()
      if (projectId) {
        window.open(config.apps.verify + projectId, '_blank').focus()
      }
    },
    generateReport() {
      const projectId = this.checkSelection()
      if (projectId) {
        window.open(config.apps.report + projectId, '_blank').focus()
      }
    },
    async createProject() {
      // Move to function that happens only when modal is loaded
      const promisesArray = []
      if (this.fieldTemplates.length <= 0) {
        promisesArray.push(
          codeListService
            .getCodeListByCategoryIDOrCode(218)
            .then((response) => {
              this.fieldTemplates = response.sort((fta, ftb) => sortUtils.naturalSort(fta['GcDescription'], ftb['GcDescription']))
            })
            .catch(this.handlerError)
        )
      }
      if (this.coordinateSystems.length <= 0) {
        promisesArray.push(
          projectionUtils.getEnabledCoordinateSystems().then(
            (enabledSystems) => {
              this.coordinateSystems = enabledSystems
            },
            (error) => {
              console.error('Failed to load coordinate system codelist!')
              console.log(error)
            }
          )
        )
      }
      this.loading = true
      await Promise.all(promisesArray)
      this.loading = false

      $('#modalNewProject').on('shown.bs.modal', (e) => {
        $('#txtNewProjectCode').focus()
      })
      $('#modalNewProject').modal('show')
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>
<style lang="less" scoped>
h2 {
  margin-left: 15px;
  font-size: 18px;
  clear: both;
}

.project-table {
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  .checkBoxWrapper {
    width: 100%;
    height: 100%;
    margin: -8px;
    padding: 8px;
  }

  input[type='checkbox'] {
    display: none;

    &+label {
      width: 20px;
      height: 20px;
      display: block;
      position: relative;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        top: 3px;
        left: 5px;
        border: 1px solid #9d9d9d;
        position: absolute;
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }

  label.checked span {
    background-color: #67ac45 !important;
  }

  .VueTables__table>tbody>tr>td {

    &:first-child,
    &:last-child {
      cursor: default;
    }
  }
}

.VueTables__table {
  &>thead>tr>th {
    border-left: none;
    border-right: none;
  }

  &>tbody>tr {
    cursor: pointer;

    &>td {
      line-height: 25px;
      border-left: none;
      border-right: none;
      padding: 5px;
    }
  }
}

.VuePagination__count {
  display: none;
}

.VueTables__search .form-control:focus {
  border: 1px solid #67ac45;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(132, 189, 96, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(132, 189, 96, 0.6);
}

.glyphicons-pushpin {
  color: #888888;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.unpin {
  color: #888888;
  -webkit-transform: rotate(deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
}

@media (max-width: 415px) {

  h2,
  .displayNone {
    display: none;
  }

  .VueTables__table>tbody>tr>td {
    line-height: 24px;
  }

  .project-table {
    padding: 0px;
    padding-top: 25px;
    margin-bottom: 10px;

    thead {
      display: none;
    }
  }

  .VuePagination__count {
    text-align: center;
  }
}
</style>
<style type="text/css" lang="less">
/* column widths */
.project-table {
  th.prCol_selected {
    width: 50px;
  }

  th.prCol_PrCode {
    width: 300px;
  }

  th.prCol_PrName {
    /* autogrow */
  }

  th.prCol_PrLocCity {
    width: 200px;
  }

  th.prCol_PrDateContentLastChanged {
    width: 150px;
  }

  th.prCol_PrCodeName {
    width: 100px;
  }

  th.prCol_PrPhaseCode {
    width: 100px;
  }

  th.prCol_pinned {
    width: 50px;
  }
}
</style>
