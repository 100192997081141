<template>
  <div class="loading-bar" :class="{ 'loading-completed': !isLoadingActive }">
    <div v-if="isLoadingActive" class="colorful-stripes">
      <div class="stripe-wrapper">
        <div v-for="(colorClass, index) in stripeColorClasses" :key="index" class="stripe" :class="colorClass"/>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'

export default {
  props: {
    isLoadingActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      stripeColorClasses: [ 'stripeOne', 'stripeTwo', 'stripeThree', 'stripeFour', 'stripeFive' ]
    }
  }
}
</script>

<style lang="less">
.loading-bar {
  width: 100%;
  height: 30px;
  background: @gray200;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .colorful-stripes {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    animation: loading 6s linear infinite;
    .stripe-wrapper {
      display: flex;
      gap: 15px;
      .stripeOne {
        background: @greenTerra300;
      }
      .stripeTwo {
        background: @greenTerra500;
      }
      .stripeThree {
        background: @greenTerra700;
      }
      .stripeFour {
        background: @greenTerra800;
      }
      .stripeFive {
        background: @greenTerra900;
      }
    }
    .stripe {
      height: 100px;
      width: 15px;
      box-sizing: border-box;
      transform: rotate(20deg);
      margin-top: -20px;
    }
    @keyframes loading {
      0% { transform: translateX(-50%); }
      100% { transform: translateX(100%); }
    }
  }
}
.loading-completed {
  background: @greenTerra600;
  transition: background 0.9s;
}
</style>
