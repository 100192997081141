<template>
  <transition name="fade" appear>
    <BasePopup
      v-show="exportPopupOpen"
      :popupOpen="exportPopupOpen"
      :popup-title="getExportPopupTitle"
      @closeButtonClicked="exportProject">
        <component
        :is="selectedComponent"
        :projectId="currentProjectId"
        :projectCode="currentProjectCode"
        :exportItems="exportItems"
        :selectedExportConfig="selectedExportConfig"
        :tableContent="tableContent"
        :exportButtonsAreEnabled="exportButtonsAreEnabled"
        :measurementPointsData="measurementPointsData"
        :exportCreated="exportCreated"
        :excludeDataFieldKeys="excludeDataFieldKeys"
        @errorOccurred="errorMessage"
        @creatingExportChanged="creatingExportChanged"
        @enableSelectExportButtons="enableSelectExportButtons"
        @setFunction="setExportPage"
        @setSelectedExportConfig="setSelectedExportConfig"
        @tableContentUpdated="setTableContent"
        @navigatedToSelectedItem="setNavigatedToSelectedItem"
        @setMeasurementPointsData="setMeasurementPointsData"
        @exportCreated="setExportCreated"
        @isLoading="setIsLoading"
        @setNextPage="setNextPage"
        @shareExportFormChanged="setShareExportFormValues"
        @goNextPage="goNextPage"/>
        <template v-slot:footer>
          <component
          :is="footerComponent"
          :projectId="projectId"
          :exportProjects="getExportProjects"
          :isCreatingExport="isCreatingExport"
          :errorMessage="customErrorMessage"
          :tableContent="getTableContent"
          :navigatedToSelectedItem="getNavigatedToSelectedItem"
          :shareExportFormValues="shareExportFormValues"
          @errorOccurred="errorMessage"
          @shareExportFormChanged="setShareExportFormValues"
          @isLoading="setIsLoading"
          @setNextPage="setNextPage"
          @goNextPage="goNextPage"/>
      </template>

      <NvLoading :show="isLoading"></NvLoading>
    </BasePopup>
  </transition>
</template>

<script>
import BasePopup from '@/components/boxes/base-components/basePopup.vue'

import SelectExportType from '@/components/export/selectExport/selectExportType.vue'

import CreateExport from '@/components/export/createExport/createExport.vue'
import CreateExportFooter from '@/components/export/createExport/createExportFooter.vue'

import ChangeExportDataSelection from '@/components/export/changeExportDataSelection/changeExportDataSelection.vue'
import ChangeExportDataSelectionFooter from '@/components/export/changeExportDataSelection/changeExportDataSelectionFooter.vue'

import ShareExport from '@/components/export/shareExport/shareExport.vue'
import ShareExportFooter from '@/components/export/shareExport/shareExportFooter.vue'
import ShareComplete from '@/components/export/shareExport/shareComplete.vue'
import ShareCompleteFooter from '@/components/export/shareExport/shareCompleteFooter.vue'

import ExportProjectFieldUpdate from './class/ExportProjectFieldUpdate'
import exportItems from '@/configurations/app/config-export'
import NvLoading from '@/components/_shared/loading.vue'

import { MeasurementPointService } from '@/services//DataWS/measurementPointService'

export default {
  name: 'ExportProjectPopup',
  components: {
    BasePopup,
    SelectExportType,
    CreateExport,
    CreateExportFooter,
    ChangeExportDataSelection,
    ChangeExportDataSelectionFooter,
    ShareExport,
    ShareExportFooter,
    ShareComplete,
    ShareCompleteFooter,
    NvLoading
  },
  props: {
    exportPopupOpen: {
      type: Boolean,
      required: true
    },
    PrID: {
      type: String,
      required: false
    },
    PrCode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      exportItems: exportItems,
      selectedComponent: 'SelectExportType',
      footerComponent: '',
      nextComponent: '',
      nextFooterComponent: '',
      customErrorMessage: '',
      selectedExportItemConfig: null,
      selectExportComponent: null,
      isCreatingExport: false,
      exportButtonsAreEnabled: true,
      tableContent: [],
      projectId: null,
      navigatedSelectedItem: null,
      isLoading: false,
      selectedExportConfig: null,
      shareExportFormValues: [],
      measurementPointsData: {},
      exportCreated: false,
      excludeDataFieldKeys: [
        {
          name: 'Measurementpoints',
          keys: ['MpGuid', 'MpName', 'MpTypeCode', 'MpXCoord', 'MpYCoord', 'MpDate', 'SlID']
        },
        {
          name: 'AnalysisSamples',
          keys: ['AnGUID', 'SampleName', 'Date', 'From', 'To', 'Matrix', 'Export']
        },
        {
          name: 'WaterSamples',
          keys: ['Id', 'SampleName', 'Date', 'Matrix', 'From', 'To', 'Export']
        },
        {
          name: 'AirSamples',
          keys: ['Id', 'SampleName', 'Date', 'Matrix', 'From', 'To', 'Export']
        }
      ]
    }
  },
  mounted() {
    this.$store.commit('clearExportInformationExportProjects')
    this.addCurrentProject()
  },
  watch: {
    async exportPopupOpen() {
      if (!this.exportPopupOpen) return
      this.$store.commit('clearExportInformationExportProjects')
      this.projectId = this.currentProjectId
      await this.addCurrentProject()
      await this.fetchMeasurementPoints()
    }
  },
  computed: {
    currentProjectCode() {
      return this.PrCode || this.$store?.state?.currentProject?.PrCode || this.$store?.state?.projects[0]?.PrCode
    },
    getExportProjects() {
      return this.$store.getters.getExportInformationExportProjects
    },
    currentProjectId() {
      return this.PrID !== undefined ? this.PrID : this.$store.state.route.params.projectId.toString()
    },
    getExportPopupTitle() {
      return this.$t('label.export')
    },
    getTableContent() {
      return this.tableContent
    },
    getNavigatedToSelectedItem() {
      return this.navigatedSelectedItem
    },
    measurementPointService() {
      return new MeasurementPointService()
    }
  },
  methods: {
    async exportProject() {
      this.$emit('closeButtonClicked', this.exportPopupOpen)
      this.selectedComponent = 'SelectExportType'
      this.footerComponent = ''
      this.exportCreated = false
    },
    async addCurrentProject() {
      if (this.getExportProjects.length > 0) return
      this.$store.commit('addExportProject', { PrID: this.currentProjectId, PrCode: this.currentProjectCode, selectedExportConfig: this.selectedExportItemConfig })
    },
    async fetchMeasurementPoints() {
      this.exportButtonsAreEnabled = false
      const tableData = await this.measurementPointService.getMeasurementPoints(this.projectId)
      const selectedRows = this.getSelectedMeasurementPointsGuids(tableData.tblMeasurementPoints)

      tableData.tblMeasurementPoints.forEach(mp => {
        Object.keys(mp).forEach(key => {
          if (!this.excludeDataFieldKeys.find(item => item.name === 'Measurementpoints').keys.includes(key)) {
            delete mp[key]
          }
        })
      })

      this.measurementPointsData = { name: 'Measurementpoints', tableData: tableData.tblMeasurementPoints, selectedRows }
      this.tableContent = this.measurementPointsData
      this.exportButtonsAreEnabled = true
    },
    getSelectedMeasurementPointsGuids(tableData) {
      return tableData.filter(row => row.MpInExports === 'true').map(row => row.MpGuid)
    },
    setSelectedExportConfig(selectedExport) {
      const selectedExportConfig = this.exportItems.flatMap(item => item.options).find(option => option.name === selectedExport)
      this.selectedExportItemConfig = selectedExportConfig
      if (!this.getExportProjects) return

      this.selectedExportConfig = new ExportProjectFieldUpdate('selectedExportConfig', selectedExportConfig)
      this.$store.commit('updateAllExportProjectsBindingKey', this.selectedExportConfig)
    },
    goNextPage() {
      this.selectedComponent = this.nextComponent
      this.footerComponent = this.nextFooterComponent
    },
    setExportPage(componentName) {
      this.selectExportComponent = componentName
    },
    setNextPage({ nextComponent, nextFooter }) {
      this.nextComponent = nextComponent
      this.nextFooterComponent = nextFooter
      this.customErrorMessage = ''
    },
    creatingExportChanged(value) {
      this.isCreatingExport = value
    },
    enableSelectExportButtons(value) {
      this.exportButtonsAreEnabled = value
    },
    errorMessage(errorMessage) {
      this.customErrorMessage = errorMessage
    },
    setTableContent(tableContent) {
      this.tableContent = tableContent
    },
    setNavigatedToSelectedItem(selectedItem) {
      this.navigatedSelectedItem = selectedItem
    },
    setIsLoading(value) {
      this.isLoading = value
    },
    setShareExportFormValues(shareExportFormValues) {
      this.shareExportFormValues = shareExportFormValues
    },
    setMeasurementPointsData(value) {
      this.measurementPointsData = value
    },
    setExportCreated(isExportCreated) {
      this.exportCreated = isExportCreated
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .popupHeader {
  padding: 15px 32px;
}
/deep/ .footer-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 32px;
  border-top: 1px solid #d5d5d5;
  .popup-footer-error {
    display: inline-flex;
    align-items: center;
    padding: 6px;
    color: @error600;
    margin: 0px;
  }
  .popup-footer-button {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 18px;
    font-size: 14px;
    height: fit-content;
    border-radius: 4px;
    border: none;
    &.primary-button {
      font-weight: 500;
      color: #fff;
      margin-left: 16px;
      background-color: @greenTerra600;
      &:hover {
        background-color: @greenTerra700;
      }
      &:disabled {
        background-color: @gray200;
        cursor: not-allowed;
      }
    }
    &.secondary-button {
      color: @gray700;
      font-weight: 500;
      background-color: white;
      border: 1px solid @gray400;
      &:hover {
        background-color: @gray100;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &.left {
        margin-right: auto;
      }
    }
    &.icon {
      font-size: 22px;
    }
  }
}
</style>
