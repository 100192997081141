import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class ProjectsOverviewService extends BaseService {
  getMyProjects() {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      ArchiveID: 0
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetMyProjectsWithUitwisselJSON`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getProjectListByFilter(option) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      ArchiveID: 0,
      SearchFilter: option.SearchFilter,
      PageSize: option.PageSize,
      LastRowNR: option.LastRowNR,
      OrderPreviewBy: option.OrderPreviewBy
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetProjectsJSON`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  pinUnPinProject(projectId, pinOrUnPin) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      ProjectID: projectId,
      Enabled: pinOrUnPin
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/UnpinAndPinProject`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
