<template>
  <div class="footer-button-container">
    <button class="popup-footer-button secondary-button left" @click="handleFooterButtonClick('CreateExport')">
      <i class="fa-regular fa-chevron-left"></i>
      {{ $t('export.label.back') }}
    </button>
    <p class="popup-footer-error">{{ errorMessage }}</p>
    <button class="popup-footer-button primary-button" :disabled="isButtonDisabled" @click="handleFooterButtonClick('ShareComplete')">
      {{ $t('export.label.send') }}
      <i class="fa-regular fa-paper-plane-top"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ShareExportFooter',
  props: {
    shareExportFormValues: {
      type: Array,
      required: true
    },
    exportProjects: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isButtonDisabled: true,
      recipientInput: null,
      subjectInput: null,
      emailInformation: {}
    }
  },
  watch: {
    shareExportFormValues: {
      handler: 'validateInputValues',
      deep: true
    }
  },
  methods: {
    validateInputValues() {
      this.recipientInput = this.shareExportFormValues.find(inputValue => inputValue.fieldKey === 'Recipient')
      this.subjectInput = this.shareExportFormValues.find(inputValue => inputValue.fieldKey === 'Subject')

      const recipientValue = this.recipientInput ? this.recipientInput.value.trim() : ''
      const subjectValue = this.subjectInput ? this.subjectInput.value.trim() : ''

      if (!this.recipientInput || !this.subjectInput || recipientValue === '') {
        this.setErrorMessage(this.$t('export.messages.fillRequiredFields'))
        return
      }

      const recipientEmails = recipientValue.split(';').map(email => email.trim())

      for (const email of recipientEmails) {
        if (!this.isValidEmail(email)) {
          this.setErrorMessage(this.$t('export.messages.invalidEmailFormat', { email }))
          return
        }
      }

      if (subjectValue === '') {
        this.setErrorMessage(this.$t('export.messages.subjectFieldRequired'))
        return
      }

      this.$emit('errorOccurred', '')
      this.isButtonDisabled = false
    },
    setErrorMessage(message) {
      this.$emit('errorOccurred', message)
      this.isButtonDisabled = true
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    async handleFooterButtonClick(page) {
      if (page !== 'ShareComplete') {
        this.$emit('setNextPage', { nextComponent: page, nextFooter: 'CreateExportFooter' })
        this.$emit('goNextPage')
        this.$emit('errorOccurred', '')
        this.$emit('shareExportFormValuesExported', [])
        return
      }

      const fileName = await this.$store.dispatch('changeFileName', this.exportProjects)
      this.addExportFile(fileName)

      if (this.isButtonDisabled) return
      this.$emit('isLoading', true)
      const result = await this.$store.dispatch('sendEmail', this.emailInformation)
      if (!result) {
        this.setErrorMessage(this.$t('export.messages.emailSendFailed'))
        this.$emit('isLoading', false)
        return
      }
      this.$emit('isLoading', false)
      const nextFooter = page === 'CreateExport' ? 'CreateExportFooter' : 'ShareCompleteFooter'
      this.$emit('setNextPage', { nextComponent: page, nextFooter })
      this.$emit('goNextPage')
    },
    addExportFile(fileName) {
      // The export files needs to be added to the email information so that the file can be sent as an attachment, but first the file content needs to be converted to base64
      const exportProject = this.exportProjects[0]
      let fileContent = exportProject.exportResponse.Files[0].FileContent

      if (!exportProject.selectedExportConfig.extension) {
        fileContent = Buffer.from(fileContent, 'utf-8').toString('base64')
        fileName = `${fileName}.xml`
      }

      // Only these two export names need to be converted to base64 with ascii encoding because these are json files
      if (exportProject.selectedExportConfig.name === 'Streamline_Export' || exportProject.selectedExportConfig.name === 'Streamline_Report_Dataset') {
        fileContent = Buffer.from(fileContent, 'ascii').toString('base64')
      }

      this.shareExportFormValues.push({ fieldKey: 'Base64Content', value: fileContent }, { fieldKey: 'FileName', value: fileName })
      this.shareExportFormValues.forEach(input => {
        this.emailInformation[input.fieldKey] = input.value
      })
      return this.emailInformation
    }
  }
}
</script>
