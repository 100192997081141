import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'

export default {
  async getTableColumns(templateName, excludedDataFieldKeys, $store) {
    try {
      const templateData = await $store.dispatch('getDefaultFieldTemplate', {
        templateName: templateName
      })

      const parsedTemplate = GuiTemplateParser.parseTemplate(templateData)
      const dateColumn = parsedTemplate.find((item) => item.dataFieldKey === 'WsDate')
      if (dateColumn) {
        dateColumn.dataType = 'date'
      }

      const dataFieldKeyReplacements = [
        { originalKey: 'WsName', replacementKey: 'SampleName' },
        { originalKey: 'WsDate', replacementKey: 'Date' },
        { originalKey: 'WsMatrixCode', replacementKey: 'Matrix' }
      ]

      dataFieldKeyReplacements.forEach(({ originalKey, replacementKey }) => {
        const tableColumn = parsedTemplate.find((tableColumn) => tableColumn.dataFieldKey === originalKey)
        if (tableColumn) tableColumn.dataFieldKey = replacementKey
      })

      parsedTemplate.forEach((column) => {
        if (!excludedDataFieldKeys.includes(column.dataFieldKey)) {
          column.visible = false
        }
      })

      const mergedColumns = [...parsedTemplate, ...this.addCustomColumns()]
      return mergedColumns
    } catch (error) {
      throw error
    }
  },

  addCustomColumns() {
    return [
      {
        caption: 'label.from',
        dataFieldKey: 'From',
        dataType: 'number',
        visible: true,
        validations: {
          dxLookup: false
        }
      },
      {
        caption: 'label.to',
        dataFieldKey: 'To',
        dataType: 'number',
        visible: true,
        validations: {
          dxLookup: false
        }
      }
    ]
  }
}
