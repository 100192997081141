<template>
  <div class="footer-button-container">
    <button class="popup-footer-button secondary-button left" @click="handleFooterButtonClick('SelectExportType')">
        <i class="fa-regular fa-chevron-left"></i>
        {{ $t('export.label.back') }}
      </button>
    <button class="popup-footer-button primary-button" :disabled="isExportButtonDisabled()" @click="handleFooterButtonClick('CreateExport')">
      {{ $t('label.export') }}
      <i class="fa-regular fa-cloud-download"></i>
    </button>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import updateSelectedExportItem from '@/utils/export/updateSelectedExportItem'

export default {
  name: 'ChangeExportDataSelectionFooter',
  props: {
    projectId: {
      type: String,
      required: true
    },
    tableContent: {
      required: true
    },
    navigatedToSelectedItem: {
      type: String,
      required: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      measurementPointsLength: 0
    }
  },
  methods: {
    async handleFooterButtonClick(page) {
      if (page !== 'SelectExportType') {
        this.$emit('isLoading', true)
        await updateSelectedExportItem.updateSelectedExportItem(this.navigatedToSelectedItem, this.projectId, this.tableContent.tableData, this.tableContent.selectedRows, this.$store)
        this.$emit('isLoading', false)
      }
      const nextFooter = page === 'CreateExport' ? 'CreateExportFooter' : ''
      this.$emit('setNextPage', { nextComponent: page, nextFooter })
      this.$emit('goNextPage')
    },
    isExportButtonDisabled() {
      if (this.tableContent.name === 'Measurementpoints') {
        this.measurementPointsLength = this.tableContent.selectedRows.length
      }
      if (this.tableContent.length === 0 || this.measurementPointsLength > 0) return false
      if (this.tableContent.name !== 'Measurementpoints' && this.measurementPointsLength === 0) return true
      return this.tableContent.selectedRows.length === 0
    }
  }
}
</script>
