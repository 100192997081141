<template>
  <div class="export-content-body">
    <div class="share-export-container">
      <div class="share-export-header">
        <p class="share-export-header-title">{{ $t('columnTitles.Email') }}</p>
      </div>
      <div class="share-export-form-container">
        <p class="share-export-form-container-title">{{ $t('export.label.share') }}:</p>
        <div v-for="(input, index) in shareExportFormFields" :key="index" class="share-export-form-input-container">
          <label :for="input.fieldKey" class="share-export-form-input-container-title">
            {{ input.label }} <span v-if="input.isRequired" class="input-required">*</span>
          </label>
          <textarea v-if="input.inputTag === 'textarea'" :id="input.fieldKey" v-model="input.value" :name="input.fieldKey" class="input" :placeholder="input.placeholder" @input="handleInputChange()"></textarea>
          <input v-else :id="input.fieldKey" v-model="input.value"  :type="input.inputType"  :name="input.fieldKey"  class="input" :placeholder="input.placeholder" @input="handleInputChange()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'

export default {
  name: 'ShareExport',
  mounted() {
    this.$emit('exportCreated', true)
  },
  data() {
    return {
      shareExportFormFields: [
        { label: this.$t('columnTitles.Email'), fieldKey: 'Recipient', placeholder: 'xxx@xx.com;yyy@yy.com', isRequired: true, value: '', inputType: 'text', inputTag: 'input' },
        { label: this.$t('export.label.subject'), fieldKey: 'Subject', placeholder: 'Enter your subject here', isRequired: true, value: '', inputType: 'text', inputTag: 'input' },
        { label: this.$t('gridColumn.localizedText'), fieldKey: 'Description', placeholder: 'Enter your description here (optional)', isRequired: false, value: '', inputType: 'text', inputTag: 'textarea' }
      ]
    }
  },
  methods: {
    handleInputChange() {
      const valuesToEmit = this.shareExportFormFields.map(({ fieldKey, value }) => ({ fieldKey, value }))
      this.$emit('shareExportFormChanged', valuesToEmit)
    }
  }
}
</script>

<style lang="less" scoped>
.export-content-body {
  width: 90vw;
  height: calc(80vh - 128px);
  .share-export-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 10px 32px 0px;
    width: 100%;
    height: 100%;
    .share-export-header {
      display: flex;
      padding: 3px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .share-export-header-title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: @gray800;
      }
    }
    .share-export-form-container {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      margin-bottom: 16px;
      .share-export-form-container-title {
        font-size: 16px;
        font-weight: 500;
        color: @gray600;
      }
      .share-export-form-input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        &:last-child {
          max-height: 100%;
          height: calc(80%);
          margin-bottom: 0px;
        }
        .share-export-form-input-container-title {
          font-size: 14px;
          font-weight: 500;
          color: @gray500;
          .input-required {
            color: red;
          }
        }
        .input {
          height: 100%;
          padding: 9px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid @gray400;
          background: @gray50;
          box-shadow: 0px 1px 2x 0px rgba(16, 24, 40, 0.05);
          color: @gray800;
          font-size: 16px;
          font-weight: 400;
          &:focus {
            outline: none;
            border: 1px solid;
          }
        }
      }
    }
  }
}
</style>
