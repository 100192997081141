<template>
  <button class="navigation-button" :class="navigationClass" :disabled="!isDisabled()" @click="updateTableNavigation()">
    <i :class="getOptionNameIcon()"></i> {{ this.$t(`label.${navigationItem.id}`) }} {{ tableDataLength() }}
  </button>
</template>

<script>
export default {
  name: 'ChangeExportTableSelectionNavigation',
  props: {
    navigationItem: {
      type: Object,
      required: false
    },
    navigationSelectedItem: {
      type: String,
      required: false
    },
    selectedExportObjectKeys: {
      type: Array,
      required: false
    },
    tableDataSummaryBySampleType: {
      type: Array,
      required: false
    },
    exportSpecificOptions: {
      type: Array,
      required: false
    },
    tableContent: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      changeTableNavigation: false
    }
  },
  computed: {
    navigationClass() {
      return {
        selected: this.isSelected(),
        activated: this.isActivated()
      }
    }
  },
  watch: {
    tableDataSummaryBySampleType() {
      if (this.tableDataSummaryBySampleType.length !== this.exportSpecificOptions.length) {
        this.$emit('isLoading', true)
        this.changeTableNavigation = false
        return
      }

      this.$emit('isLoading', false)
      this.changeTableNavigation = true
    }
  },
  methods: {
    updateTableNavigation() {
      this.changeTableNavigation = true
      this.$emit('tableNavigationUpdated', this.navigationItem)
    },
    isSelected() {
      return this.navigationItem.id === this.navigationSelectedItem
    },
    hasAllOptions() {
      if (!this.selectedExportObjectKeys) return false
      return this.selectedExportObjectKeys.some(item => {
        return item.id === this.navigationItem.id
      })
    },
    isActivated() {
      return this.hasAllOptions() ? 'activated' : ''
    },
    isDisabled() {
      return this.hasAllOptions()
    },
    getOptionNameIcon() {
      switch (this.navigationItem.id) {
        case 'Measurementpoints':
          return 'fa-regular fa-bore-hole'
        case 'AnalysisSamples':
          return 'fa-regular fa-prescription-bottle'
        case 'AirSamples':
          return 'fa-regular fa-wind'
        case 'WaterSamples':
          return 'fa-regular fa-water'
        default:
          return 'fa-regular fa-question'
      }
    },
    tableDataLength() {
      if (this.tableDataSummaryBySampleType.length !== this.exportSpecificOptions.length) return
      const matchingData = this.tableDataSummaryBySampleType.filter(tableDataSummary => tableDataSummary.name === this.navigationItem.id)
      if (matchingData.length === 0) return
      const totalLength = matchingData.reduce((sum, tableDataSummary) => sum + tableDataSummary.tableDataLength, 0)
      if (this.tableContent.name === this.navigationItem.id) {
        return `(${this.tableContent.selectedRows.length}/${totalLength})`
      }
      const selectedRowsLength = matchingData.reduce((sum, tableDataSummary) => sum + tableDataSummary.selectedRowsLength, 0)
      return `(${selectedRowsLength}/${totalLength})`
    }
  }
}
</script>

<style lang="less" scoped>
  .navigation-button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: white;
    border: none;
    border-right: 1px solid @gray400;
    font-style: normal;
    font-weight: 400;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: none;
    }
    &.activated:hover {
      background-color: @gray100;
    }
    &:not(.activated) {
      background-color: white;
      opacity: .3;
      &:hover {
        cursor: not-allowed;
      }
    }
    &.selected {
      font-weight: 700;
      background-color: @gray200;
    }
  }
</style>
