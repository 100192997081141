<template>
  <ExportDynamicTable
    :tableData="getSelectedNavigationTableData"
    :tableDataIdentifier="tableDataIdentifier"
    :tableName="tableName"
    :tableColumns="tableColumns"
    :projectId="projectId"
    :language="language"
    :isValidRequest="isValidRequest"
    :selectedRowKeys="getSelectedNavigationSelectedRows"
    :navigationSelectedItem="navigationSelectedItem"
    @tableContentUpdated="setWaterSamplesTableContent"/>
</template>

<script>
import ExportDynamicTable from '@/components/export/tables/exportDynamicTable'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import generateWaterAirSamplesColumnsUtils from '@/utils/export/generateWaterAirSampleColumnsUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'WaterSamplesExportTable',
  components: {
    ExportDynamicTable
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    tableDataPerSampleType: {
      type: Array,
      required: true
    },
    navigationSelectedItem: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      tableColumns: [],
      tableDataIdentifier: 'Id',
      tableName: 'tblWaterSamples'
    }
  },
  async created() {
    this.$emit('isLoading', true)
    await this.getWaterSamplesTableColumns()
    this.$emit('isLoading', false)
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    language() {
      return Cookie.get('language')
    },
    getSelectedNavigationTableData() {
      return this.getWaterSamplesTableData().tableData
    },
    getSelectedNavigationSelectedRows() {
      return this.getWaterSamplesTableData().selectedRows
    }
  },
  methods: {
    getWaterSamplesTableData() {
      return this.tableDataPerSampleType.find(item => item.name === this.navigationSelectedItem)
    },
    async getWaterSamplesTableColumns() {
      try {
        this.tableColumns = await generateWaterAirSamplesColumnsUtils.getTableColumns('frmWatersampleGrid', ['SampleName', 'Date', 'Matrix'], this.$store)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    setWaterSamplesTableContent(waterSamplesTableContent) {
      this.$emit('tableContentUpdated', waterSamplesTableContent)
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
