<template>
  <div class="sidebar-menu">
    <div
      v-for="exportItem in filteredExportItemsForTerraindex"
      :key="exportItem.exportServerIdentifier"
      class="sidebar-menu-box"
      :class="appendMenuItemExpandedClass(exportItem.group)">
      <div
        class="sidebar-menu-item"
        :class="appendSelectedClass(exportItem.group)"
        @click="onClickSidebarExportItem(exportItem)">
        <p class="sidebar-menu-item-text">
          {{ exportItem.group }}
        </p>
        <div v-if="hasMultipleExportOptions(exportItem)" class="sidebar-menu-item-icon menu-item-extended-icon">
          <i :class="`fa-solid fa-caret-${getCollapsibleIcon(exportItem.group)} icon`"></i>
        </div>
      </div>
      <div class="sidebar-list" :class="appendCollapsedListClass(exportItem.group)">
        <div
          v-for="exportOption in exportItem.options"
          :key="exportOption.name"
          class="sidebar-list-item sidebar-menu-item"
          :class="appendSelectedClass(exportOption.name)"
          @click="setSelectedExportItem(exportOption.name)">
          <p class="sidebar-menu-item-text">
            {{ getExportOptionTitle(exportOption.name) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import cookie from 'vue-cookie'

export default {
  name: 'SelectExportSideBar',
  props: {
    exportItems: {
      type: Array,
      required: true
    },
    selectedExportItem: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      expandedMenuItem: ''
    }
  },
  computed: {
    filteredExportItemsForTerraindex() {
      return this.exportItems.filter(item => item.group !== 'Internal' || this.userIsAllowedToAccess)
    },
    userIsAllowedToAccess() {
      const username = cookie.get('username')
      return (username.includes('@terraindex.com') || username.includes('veenstra@pjmilieu.nl') || username.includes('sjoerd.hoekstra@tauw.com'))
    }
  },
  methods: {
    onClickSidebarExportItem(exportItem) {
      if (exportItem.options && exportItem.options.length > 1) {
        this.expandMenuItem(exportItem.group)
        return
      }
      this.setSelectedExportItem(exportItem.options[0].name)
    },
    setSelectedExportItem(selectedExportName) {
      this.$emit('setSelectedExportItem', selectedExportName)
      this.$emit('setSelectedExportConfig', selectedExportName)
      this.$emit('enableSelectExportButtons', true)
    },
    setExpandedMenuItemSetting(item) {
      if (this.expandedMenuItem === item) {
        this.expandedMenuItem = ''
        return
      }
      this.expandedMenuItem = item
    },
    hasMultipleExportOptions(item) {
      return item.options && item.options.length > 1
    },
    expandMenuItem(exportItemGroup) {
      this.$emit('setSelectedExportItem', exportItemGroup)
      this.$emit('enableSelectExportButtons', false)
      this.setExpandedMenuItemSetting(exportItemGroup)
    },
    getCollapsibleIcon(exportGroup) {
      return this.isExportGroupDisplayed(exportGroup) ? 'up' : 'down'
    },
    appendCollapsedListClass(exportGroup) {
      return this.isExportGroupDisplayed(exportGroup) ? '' : 'collapsed'
    },
    appendSelectedClass(key = '') {
      return this.selectedExportItem === key ? 'selected' : ''
    },
    appendMenuItemExpandedClass(exportGroup) {
      return this.isExportGroupDisplayed(exportGroup) ? 'menu-item-expanded' : ''
    },
    isExportGroupDisplayed(exportGroup) {
      return this.expandedMenuItem === exportGroup
    },
    getExportOptionTitle(exportOption) {
      return this.$t(`export.${exportOption}.title`)
    }
  }
}
</script>

<style lang="less" scoped>
.export-content-body {
  display: flex;
  width: 90vw;
  height: calc(80vh - 66px);
}
.sidebar-menu {
  width: 270px;
  height: 100%;
  overflow-y: auto;
  min-width: 270px;
  border-right: 1px solid @gray300;
  border-radius: 0px 0px 0px 10px;
  position: relative;
  .sidebar-menu-box {
    display: grid;
    min-height: 56px;
    align-items: center;
  }
  .menu-item-expanded {
    border-bottom: 1px solid @gray300;
  }
  .sidebar-list {
    overflow-y: auto;
    .collapsed {
      opacity: 0;
      pointer-events: none;
    }
    &.collapsed {
      height: 0;
    }
    .sidebar-list-item {
      padding-left: 36px;
      height: 56px;
      align-items: center;
      display: flex;
    }
  }
  &.collapsed {
    width: 50px;
    min-width: 50px;
  }
  .sidebar-menu-item {
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: 56px;
    &:hover {
      cursor: pointer;
      background-color: @gray100;
    }
    .collapsed {
      opacity: 0;
      pointer-events: none;
    }
    &.selected {
      background-color: @gray300;
    }
    .sidebar-menu-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 56px;
      .icon {
        font-size: 22px;
        color: @gray700;
      }
    }
    .menu-item-extended-icon {
      width: 50px;
    }
    .sidebar-menu-item-text {
      font-weight: 500;
      flex: 1;
      margin: auto;
    }
  }
}
</style>
