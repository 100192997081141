<template>
  <div>
    <DxDataGrid
      class="data-grid"
      columnRenderingMode="virtual"
      :data-source="tableData"
      :key-expr="tableDataIdentifier"
      :projectId="projectId"
      :language="language"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :show-borders="true"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :useMultiSelect="true"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :isValidRequest="isValidRequest"
      :selectedRowKeys="selectedRowKeys"
      @selection-changed="onSelectionChanged"
      @content-ready="onContentReady">
      <DxPaging :enabled="false"/>
      <DxScrolling column-rendering-mode="virtual" />
      <DxSelection :deffered="true" show-check-boxes-mode="always" mode="multiple" select-all-mode="page" />
      <DxHeaderFilter :visible="true" />
      <DxColumn
        v-for="tableColumn in tableColumns"
        :key="tableColumn.dataFieldKey"
        :caption="$t(tableColumn.caption) || tableColumn.caption"
        :calculate-display-value="tableColumn.calculateDisplayValue"
        :calculate-cell-value="tableColumn.calculateCellValue"
        :customize-text="customizeColumnText"
        :data-field="tableColumn.dataFieldKey"
        :data-type="tableColumn.dataType"
        :format="(...value) => formatCellValue(tableColumn, ...value)"
        :set-cell-value="tableColumn.setCellValue"
        :sorting-method="(a, b) => naturalSort(a, b, tableColumn)"
        :visible="tableColumn.visible"
        group-cell-template="groupCellTemplate" >
        <DxLookup
          v-if="tableColumn.validations.dxLookup && tableColumn.validations.dxLookupValidationProperties.displayProperty && tableColumn.validations.dxLookupValidationProperties.valueProperty"
          :display-expr="tableColumn.validations.dxLookupValidationProperties.displayProperty"
          :value-expr="tableColumn.validations.dxLookupValidationProperties.valueProperty"
          :data-source="tableColumn.validations.dxLookupValidationProperties.options" />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPaging,
  DxScrolling,
  DxSelection,
  DxHeaderFilter,
  DxColumn,
  DxLookup
} from 'devextreme-vue/data-grid'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { formatDate, loadMessages, locale } from 'devextreme/localization'

import sortUtils from '@/utils/sortUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'exportDynamicTable',
  components: {
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxSelection,
    DxHeaderFilter,
    DxColumn,
    DxLookup
  },
  props: {
    tableData: {
      required: true,
      default: () => ([])
    },
    tableDataIdentifier: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    tableColumns: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    tableName: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    projectId: {
      type: String,
      required: true
    },
    template: {
      type: Object,
      required: false,
      default: () => ({})
    },
    language: {
      type: String,
      required: true,
      default() {
        return 'en'
      }
    },
    isValidRequest: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    selectedRowKeys: {
      type: Array,
      required: true,
      default: () => ([])
    },
    navigationSelectedItem: {
      type: String,
      required: false
    }
  },
  async created() {
    this.$emit('isLoading', true)
    if (this.language && this.language !== 'en') {
      switch (this.language) {
        case 'nl':
          loadMessages(nlDevExtremeLocale)
          break
        case 'fr':
          loadMessages(frDevExtremeLocale)
          break
        case 'de':
          loadMessages(deDevExtremeLocale)
          break
        case 'es':
          loadMessages(esDevExtremeLocale)
          break
        case 'it':
          loadMessages(itDevExtremeLocale)
          break
      }
      locale(this.language)
    }
  },
  computed: {
    tableGridInstrance() {
      return this && this.$refs.tableGrid && this.$refs.tableGrid.$_instance ? this.$refs.tableGrid.$_instance : false
    }
  },
  methods: {
    formatCellValue(column, value) {
      try {
        switch (column.dataType) {
          case 'date':
            return formatDate(value, 'yyyy-MM-dd')
          case 'datetime':
            // format date time as date when grouping
            if (this.tableGridInstrance.columnOption(column.dataFieldKey).groupIndex > -1) {
              return formatDate(value, 'yyyy-MM-dd')
            }
            return formatDate(value, 'yyyy-MM-dd HH:mm:ss')
          default:
            return null
        }
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    naturalSort(a, b, tableColumn) {
      switch (tableColumn.dataType) {
        case 'datetime':
        case 'date':
          if (typeof a === 'undefined') {
            return -1
          }
          if (typeof b === 'undefined') {
            return 1
          }

          // normal sorting
          if (typeof a === 'object' && typeof b === 'object') {
            return a - b
          }
          // sorting while grouped on a column
          if (typeof a === 'string' && typeof b === 'string') {
            return new Date(a) - new Date(b)
          }
      }
      return sortUtils.anotherNaturalSort(a, b)
    },
    customizeColumnText(cellInfo) {
      if (cellInfo && cellInfo.value === 0) {
        return '0'
      }

      return cellInfo.valueText
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    onSelectionChanged(event) {
      this.$emit('tableContentUpdated', {
        name: this.navigationSelectedItem,
        selectedRows: event.selectedRowsData,
        tableData: this.tableData
      })
    },
    onContentReady() {
      this.$emit('isLoading', false)
    }
  }
}
</script>

<style lang="less" scoped>
.data-grid {
  height: 100%;
  width: 100%;
}
/deep/.dx-widget.dx-visibility-change-handler {
  width: 100% !important;
}
/deep/.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
/deep/.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
  border: 0;
}
/deep/ .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr:nth-child(even) {
  background-color: red;
}
/deep/.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
/deep/.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td {
  background-color: rgb(151, 214, 120);
  color: black;
}
/deep/.dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
  border: 1px solid #ddd;
}

/deep/.dx-scrollable-scroll {
  background-color: @gray800;
}
</style>
