<template>
  <div id="pincode-expired-modal" :class="modalClass">
    <div id="pincode-expired-modal-content">
      <h3>{{ $t('message.generatePincode.popup.title') }}</h3>
      <p>{{ $t('message.generatePincode.popup.body') }}: {{pincodeExpirationText}}</p>
      <p>{{ $t('message.generatePincode.popup.question') }}</p>
      <button target="_blank" @click="onGenerateClick">{{ $t('message.generatePincode.popup.generate') }}</button>
      <button style="white-space: normal" @click="onDismissClick">{{ $t('message.generatePincode.popup.close') }}</button>
    </div>
  </div>
</template>

<script>
import config from '@/configurations/config'
import authenticationProviders from '@/utils/authenticationProvider'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'

export default {
  name: 'PincodeExpiredNotificationModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    pincodeExpirationText: {
      type: String,
      required: true
    }
  },
  created() {
    this.authenticationProvider = authenticationProviders[config.platform].default
  },
  computed: {
    modalClass() {
      return this.visible ? 'auth-modal-visible' : 'auth-modal-hidden'
    }
  },
  methods: {
    onGenerateClick() {
      const token = this.cookieToken()
      if (token) {
        const url = config.apps.web + 'generate-pincode?id_token=' + token
        window.open(url, '_blank', 'noreferrer')

        // option within same window with redirect
        // const url = config.apps.web 'generate-pincode?id_token=' + this.queryToken() + '&from=' + location.href
        // location.href = url
      }
    },
    onDismissClick() {
      this.$emit('modalDismissed')
    },
    cookieToken() {
      const token = cookie.get('idToken')
      if (token) {
        return token
      } else {
        console.log('Error: cookie with id_token not found')
        return 'cookie_not_found'
      }
    }
  }
}
</script>

<style lang="less" scoped>
#pincode-expired-modal {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

#pincode-expired-modal-content {
  background-color: white;
  text-align: center;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 3px 2px rgba(100,100,100,0.5);
  border-radius: 10px;
  padding: 30px;
}

p:first-child {
  margin-top: 1em;
}

p {
  flex: 3;
  margin: 0 3em 1em;
}

& button {
    height: 50px;
    padding: 0;
    margin: 1em 0;
    border: 0;
    background-color: #89b368;
    color: white;
    border-radius: 12px;
    display: block;
    box-shadow: 0 2px 0 #658b49;
    min-width: 300px;
    max-width: 350px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    transition:all 0.5s cubic-bezier(.36,.03,.28,.99);

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      outline:none;
      color:white;
      background-image: linear-gradient(to bottom, #658B49, #658B49);
    }
}

button:last-child {
  margin-top: 0;
}

.auth-modal-visible {
  display: flex;
}

.auth-modal-hidden {
  display: none;
}
</style>
