<template>
  <ExportDynamicTable
    :tableData="getSelectedNavigationTableData"
    :tableDataIdentifier="tableDataIdentifier"
    :tableName="tableName"
    :tableColumns="tableColumns"
    :projectId="projectId"
    :language="language"
    :selectedRowKeys="getSelectedNavigationSelectedRows"
    :isValidRequest="isValidRequest"
    :navigationSelectedItem="navigationSelectedItem"
    @tableContentUpdated="setAirSamplesTableContent"/>
</template>

<script>
import ExportDynamicTable from '@/components/export/tables/exportDynamicTable'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import generateWaterAirSamplesColumnsUtils from '@/utils/export/generateWaterAirSampleColumnsUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'AirSamplesExportTable',
  components: {
    ExportDynamicTable
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    tableDataPerSampleType: {
      type: Array,
      required: true
    },
    navigationSelectedItem: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      tableColumns: [],
      tableDataIdentifier: 'Id',
      tableName: 'tlbAirSamples'
    }
  },
  async created() {
    this.$emit('isLoading', true)
    await this.getAirSampleTableColumns()
    this.$emit('isLoading', false)
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    language() {
      return Cookie.get('language')
    },
    getSelectedNavigationTableData() {
      return this.getAirSamplesTableData().tableData
    },
    getSelectedNavigationSelectedRows() {
      return this.getAirSamplesTableData().selectedRows
    }
  },
  methods: {
    getAirSamplesTableData() {
      return this.tableDataPerSampleType.find(item => item.name === this.navigationSelectedItem)
    },
    async getAirSampleTableColumns() {
      try {
        this.tableColumns = await generateWaterAirSamplesColumnsUtils.getTableColumns('frmAirsampleGrid', ['SampleName', 'Date', 'Matrix'], this.$store)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    setAirSamplesTableContent(airSamplesTableContent) {
      this.$emit('tableContentUpdated', airSamplesTableContent)
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
