<template>
  <div class="selected-export-container">
    <p class="selected-export-title">{{ getSelectedItemTitle }}</p>
    <div class="selected-export-options-container">
      <div class="selected-export-options">
        <div v-if="!getMultipleExportOptions && hasBetaExport" class="selected-export-options-beta-container">
          <div class="selected-export-options-beta-title">
            <i class="fa-solid fa-circle-info"></i>{{ $t('export.label.betaTitle') }}
          </div>
          <p class="selected-export-options-beta-description">{{ $t('export.label.betaDescription') }}</p>
        </div>
        <div v-if="getSelectedExportObjectsKeys.length > 0" class="selected-export-options-in-export-container">
          <p class="selected-export-options-in-export-title">{{ $t('export.label.inExport') }}</p>
          <div class="selected-export-options-in-export-boxes-container">
            <SelectExportOption
              v-for="optionName in getSelectedExportObjectsKeys"
              :key="optionName"
              :optionName="optionName"
              :selectedExportObject="selectedExportObject" />
          </div>
        </div>
        <div class="selected-export-options-description-container">
          <p class="selected-export-options-description-title">{{ $t('export.label.description') }}</p>
          <div class="selected-export-options-description">
            {{ getSelectedItemDescription }}
          </div>
        </div>
      </div>

      <div v-if="selectedExportReferences.length > 0" class="selected-export-options-references-container">
        <p class="selected-export-options-references-title">{{ $t('export.label.references') }}</p>
        <div class="selected-export-options-references-box">
          <a v-for="(reference, index) in selectedExportReferences" :key="index" :href="reference.url" target="_blank" class="selected-export-options-references-text">
            {{ reference.title }} <i class="fa-regular fa-arrow-up-right-from-square"></i>
          </a>
        </div>
      </div>
    </div>

    <SelectExportTypeFooter
      :exportButtonsAreEnabled="exportButtonsAreEnabled"
      @setNextPage="setNextPage"
      @goNextPage="goNextPage" />
  </div>
</template>

<script>
import '@/assets/less/colors.less'
import SelectExportOption from '@/components/export/selectExport/selectExportOption.vue'
import SelectExportTypeFooter from '@/components/export/selectExport/selectExportTypeFooter.vue'

export default {
  name: 'SelectExportInformation',
  components: {
    SelectExportOption,
    SelectExportTypeFooter
  },
  props: {
    exportItems: {
      type: Array,
      required: true
    },
    selectedExportItem: {
      type: String,
      required: true
    },
    exportButtonsAreEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      optionNames: ['Measurementpoints', 'AnalysisSamples', 'WaterSamples', 'AirSamples']
    }
  },
  computed: {
    getSelectedItemTitle() {
      return this.$t(`export.${this.selectedExportItem}.title`)
    },
    getSelectedItemDescription() {
      return this.$t(`export.${this.selectedExportItem}.description`)
    },
    getMultipleExportOptions() {
      return this.exportItems.filter(item => item.options && item.options.length > 1 && item.group === this.selectedExportItem).length > 0
    },
    selectedExportObject() {
      return this.exportItems
        .flatMap(item => item.options)
        .find(option => option.name === this.selectedExportItem) || {}
    },
    selectedExportReferences() {
      return this.selectedExportObject.references || []
    },
    getSelectedExportObjectsKeys() {
      const exportObjectKeys = Object.keys(this.selectedExportObject)
      const selectedOptions = this.optionNames.filter(optionName => exportObjectKeys.includes(optionName))
      return selectedOptions
    },
    hasBetaExport() {
      const selectedExport = this.selectedExportObject
      if (!selectedExport) return false
      if (selectedExport.beta === undefined || selectedExport.name !== this.selectedExportItem) return false
      return true
    }
  },
  methods: {
    setNextPage({ nextComponent, nextFooter }) {
      this.$emit('setNextPage', { nextComponent, nextFooter })
    },
    goNextPage() {
      this.$emit('goNextPage')
    }
  }
}
</script>

<style lang="less" scoped>
.selected-export-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 32px;
  gap: 10px;
  overflow: auto;
  .selected-export-title {
    margin: 16px 0px 16px 0px;
    color: @gray800;
    font-size: 20px;
    font-weight: 600;
  }
  .selected-export-options-container {
    display: flex;
    flex: 1;
    .selected-export-options {
      justify-content: space-between;
      flex: 1;
      .selected-export-options-beta-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        max-width: 800px;
        padding: 16px;
        border-radius: 6px;
        background: @blue100;
        color: @blue900;
        margin-bottom: 24px;
        .selected-export-options-beta-title {
          display: flex;
          align-items: center;
          gap: 11px;
          align-self: stretch;
          font-size: 18px;
          font-weight: 600;
        }
        .selected-export-options-beta-description {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .selected-export-options-in-export-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 24px;
        align-self: stretch;
        .selected-export-options-in-export-title {
          color: @gray700;
          font-size: 16px;
          font-weight: 600;
        }
        .selected-export-options-in-export-boxes-container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 8px;
          background-color: white;
        }
      }
      .selected-export-options-description-container {
        display: flex;
        max-width: 75%;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        gap: 12px;
        white-space: pre-line;
        .selected-export-options-description-title {
          color: @gray800;
          font-size: 16px;
          font-weight: 600;
        }
        .selected-export-options-description {
          color: @gray700;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .selected-export-options-references-container {
    display: flex;
    min-width: 200px;
    flex-direction: column;
    .selected-export-options-references-title {
      color: @gray700;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .selected-export-options-references-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .selected-export-options-references-text {
        color: @greenTerra600;
        font-size: 16px;
        font-weight: 500;
        gap: 4px;
      }
    }
  }
}

p {
  margin: 0;
}

</style>
